<template>
  <div class="login-wrapper columns">
    <div class="column is-8 is-hidden-mobile hero-banner">
      <section class="hero is-fullheight is-dark">
        <div class="hero-body">
          <div class="container section">
            <div class="has-text-right">
            </div>
          </div>
        </div>
        <div class="hero-footer">
          <p class="has-text-centered"><!--Image Credits--></p>
        </div>
      </section>
    </div>
    <div class="column is-4">
      <section class="hero is-fullheight">
        <div class="hero-heading">
          <div class="section has-text-centered">
            <img src="../../assets/logo.svg" alt="Logo of qwiz.tv">
          </div>
        </div>
        <div class="hero-body">
          <div class="container">
            <div class="columns">
              <div class="column is-8 is-offset-2">
                <div class="login-form">
                  <router-view />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'login',
  created() {
    document.querySelector('body').classList.remove('has-navbar-fixed-top');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hero-body {
  padding-top: 0;
}

.hero.is-dark .section {
  background-color: transparent;
}

.login-wrapper {
  margin: -0.75rem;
  overflow-y: hidden;
}

.hero-banner .hero {
  background: url(../../../src/assets/q-background-reverse.png);

  /* background: url('../../assets/photo_login.jpg');
  background-position: center;
  background-size: cover;
  background-blend-mode: screen; */
}

.hero-banner .title {
  display: inline-block;
  background-color: rgba(0,0,0, 0.6);
  padding: 5px;
}
</style>
