<template>
  <div>
    <div class="notification is-danger" v-show="inError">
      <button class="delete"></button>
      The provided login information doesn't match our database.
    </div>
    <div class="field">
      <div class="control has-icons-right">
        <input
          v-model="username"
          class="input email-input"
          type="text"
          placeholder="jsmith@example.org">
        <span class="icon is-right">
          <i class="fas fa-user"></i>
        </span>
      </div>

    </div>
    <div class="field">
      <div class="control has-icons-right">
        <input
          v-model="password"
          class="input password-input"
          type="password" placeholder=""
          v-on:keyup="checkForSubmission">
        <span class="icon is-right">
          <i class="fas fa-lock"></i>
        </span>
      </div>
    </div>
    <div class="field">
      <p class="control login">
        <button
          :class="[querying ? 'is-loading' : '',querying ? '' : 'is-outlined',]"
          class="button is-success is-fullwidth"
          v-on:click="login">Connexion</button>
      </p>
    </div>
    <div class="field">
      <p class="control login">
        <button class="button is-info is-fullwidth" @click="loginWithProvider('facebook')">
          <span class="icon"><i class="fab fa-facebook" aria-hidden="true"></i></span>
          <span>Me connecter avec Facebook</span>
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase';
import goto from './goto-mixin';

export default {
  name: 'login-connect',
  mixins: [goto],
  data() {
    return {
      username: '',
      password: '',
      querying: false,
      inError: false,
    };
  },
  methods: {
    login() {
      firebase.auth().signInWithEmailAndPassword(this.username, this.password).then(
        (/* user */) => {
          const to = this.$route.query.after || '/';
          this.$router.replace(to);
        },
        (err) => {
          this.$buefy.dialog.alert(err.message);
        },
      );
    },
    async loginWithProvider(providerStr) {
      const providers = {
        facebook: firebase.auth.FacebookAuthProvider,
      };
      const provider = new providers[providerStr]();

      try {
        await firebase.auth().signInWithRedirect(provider);
        const to = this.$route.query.after || '/';
        await this.$router.replace(to);
      } catch (error) {
        console.log('login component', error);
      }
    },
    checkForSubmission(e) {
      if (e.keyCode === 13) {
        this.login();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.forgot-password a {
  color: #95A5A6;
  font-weight: bold;
  padding-right: 20px;
}

</style>
